<template>
  <section class="section-wrapper"
           id="promo">
    <div class="wrapper">
      <div class="promo-block">
        <div class="">
          <h1 class="promo-title">
            <img src="@/assets/visual-logo.webp" class="desktop-logo" />

            <img src="@/assets/visual-logo@mobile.webp" class="mobile-logo" />
          </h1>

          <router-link class="button button-orange mt-4"
                       onclick="ym(94436170,'reachGoal','2024')"
                       :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'">
            Участвовать
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Promo',

  data: () => ({
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: #bee6fe;
  background-repeat: no-repeat;
  padding: 0;
  background-position: center center;
  color: $white;
  height: calc(100vh - 68px);
  background-image: url('@/assets/visual-blend.webp');
  background-size: cover;
  display: flex;
  font-family: $regular;
  justify-content: center;

  @media(max-width: 1200px){
    padding-left: 60px;
    padding-right: 60px;
  }

  @media(max-width: 1050px){
    padding-left: 35px;
    padding-right: 35px;
    align-items: flex-start;
    padding-top: 110px;
    background-image: url('@/assets/visual-bg.webp');
    background-size: contain;
    background-position: bottom center;
  }

  @media(max-width: 820px){
    align-items: flex-start;
    background-image: url('@/assets/visual-bg@tablet.webp');
    background-size: 100%;
    background-position: right bottom;
    height: 51vh;
  }

  @media(max-width: 576px){
    padding-left: 20px;
    padding-top: 90px;
    background-image: url('@/assets/visual-bg@mobile.webp');
    height: calc(100vh - 190px);
  }

  @media(max-width: 470px){
    background-position: bottom right;
    padding-top: 110px;
    height: calc(100vh - 140px);
  }

  @media(max-width: 420px){
    background-position: bottom right;
    height: calc(100vh - 200px);
    // background-size: contain;
  }

  @media(max-width: 390px){
    padding-top: 85px;
  }
}

.wrapper {
  max-width: 1700px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-image: url('@/assets/visual-bg.webp');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  padding: 55px 110px;

  @media(max-width: 1600px){
    max-width: 1300px;
    padding: 5px 75px;
  }

  @media(max-width: 1400px){
    max-width: 1130px;
    padding: 75px 75px;
  }

  @media(max-width: 1200px){
    padding: 0 25px;
    max-width: 880px;
    background: transparent;
  }

  @media(max-width: 1050px){
    padding: 0 15px;
    max-width: 880px;
    background: transparent;
  }

  @media(max-width: 756px){
    padding: 0px;
    background: transparent;
  }
}

.promo-block {
  display: flex;
  align-items: center;
  height: fit-content;
  padding-top: 159px;

  @media(max-width: 1400px){
    padding-top: 70px;
  }

  @media(max-width: 756px){
    padding-top: 0px;
  }

  .img {
    max-width: 490px;
    margin-right: 35px;
  }

  .promo-title {
    font-weight: 700;
    font-size: 82px;
    text-transform: uppercase;
    font-family: $regular;
    z-index: 2;
    position: relative;

    @media(max-width: 1600px){
      font-size: 70px;
    }

    @media(max-width: 1050px){
      font-size: 40px;
    }

    @media(max-width: 420px){
      margin-bottom: 0;
    }

    @media(max-width: 350px){
      font-size: 32px;
    }
  }

  .button {
    min-width: 250px;
    height: 59px;
    border-radius: 52px;

    &:hover {
      text-decoration: none;
    }

    @media(max-width: 1050px){
      min-width: 180px;
      max-width: 190px;
      height: 53px;
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
    }

    @media(max-width: 420px){
      min-width: 180px;
      margin-top: 15px !important;
      max-width: 190px;
    }

    @media(max-width: 350px){
      margin-top: 15px !important;
    }
  }
}

.desktop-logo {
  max-width: 600px;

  @media(max-width: 1400px){
    max-width: 540px;
  }

  @media(max-width: 1050px){
    max-width: 440px;
  }

  @media(max-width: 576px){
    display: none;
  }
}

.mobile-logo {
  display: none;

  @media(max-width: 576px){
    display: block;
    max-width: 320px;
    margin-bottom: 48px;
    transform: scale(130%);
    margin-left: 45px;
  }

  @media(max-width: 500px){
    display: block;
    max-width: 260px;
    margin-bottom: 48px;
    transform: scale(120%);
    margin-left: 20px;
  }

  @media(max-width: 390px){
    margin-bottom: 28px;
    max-width: 250px;
    transform: none;
    margin-left: 0;
  }
}
</style>
