import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    user: null,
    rules: null,
    policies: null,
    certs: null,
    receipts: null,
    personalData: null,
    errorText: '',
    winners: [],
    successText: '',
    voucherData: {
      fp: '',
      fn: '',
      fd: '',
      t: '',
      s: '',
      date: '',
      time: '',
      files: ''
    },
    unregisteredPhone: '',
    registeredPhone: '',
    loginOption: 'phone'
  },
  mutations: {
    saveUser(state, data) {
      state.user = data
    },
    saveToken(state, data) {
      state.token = data
    },
    getSuccessText(state, data) {
      state.successText = data
    },
    getErrorText(state, data) {
      state.errorText = data
    },
    setReceipts(state, data) {
      state.receipts = data
    },
    setWinners(state, data) {
      state.winners = data
    },
    setCerts(state, data) {
      state.certs = data
    },
    saveRules(state, data){
      state.rules = data
    },
    savePersonalData(state, data){
      state.personalData = data
    },
    savePolicies(state, data){
      state.policies = data
    },
    saveUnregisteredPhone(state, data){
      state.unregisteredPhone = data
    },
    saveRegisteredPhone(state, data){
      state.registeredPhone = data
    },
    saveVoucherDataFp(state, data){
      state.voucherData.fp = data
    },
    saveVoucherDataFn(state, data){
      state.voucherData.fn = data
    },
    saveVoucherDataFd(state, data){
      state.voucherData.fd = data
    },
    saveVoucherDataT(state, data){
      state.voucherData.t = data
    },
    saveVoucherDataS(state, data){
      state.voucherData.s = data
    },
    saveVoucherDataDate(state, data){
      state.voucherData.date = data
    },
    saveVoucherDataTime(state, data){
      state.voucherData.time = data
    },
    saveVoucherDataFiles(state, data){
      state.voucherData.files = data
    },
    saveLoginOption(state, data){
      state.loginOption = data
    },
  },
  actions: {
    getUser({commit, payload}){
      axios.get('profile/account', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Access-Control-Allow-Origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
        commit('saveUser', response.data.data)
      })
    },

    getDocuments({commit}, payload) {
      axios.get('reference/documents', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Access-Control-Allow-Origin': "*"
        }
      }).then((response) => {
        commit('saveRules', response.data.data.filter(data => data.type == 'rules'))
        commit('savePolicies', response.data.data.filter(data => data.type == 'privacy_policy'))
        commit('savePersonalData', response.data.data.filter(data => data.type == 'personal_data'))
      })
    },

    getReceipts({commit}){
      axios.get('profile/receipts?perPage=130', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Access-Control-Allow-Origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
          commit('setReceipts', response.data.data)
      })
    },

    getWinners({commit}){
      axios.get('/reference/winners/june?perPage=150', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Access-Control-Allow-Origin': "*"
        }
      }).then((response) => {
          commit('setWinners', response.data.data.sort((a, b) => {
            const nameA = a.prize.toUpperCase()
            const nameB = b.prize.toUpperCase()

            if (nameA == "Сертификат") {
              return -1
            }
            if (nameA != "Сертификат") {
              return 1
            }
          
            return 0
          }).sort())
      })
    },

    getCerts({commit}){
      axios.get('certs', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Access-Control-Allow-Origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
          commit('setCerts', response.data.data)
      })
    },
  },
  getters : {
    user: state => {
      return state.user
    },
    token: state => {
      return state.token
    },
    errorText: state => {
      return state.errorText
    },
    receipts: state => {
      return state.receipts
    },
    certs: state => {
      return state.certs
    },
    successText: state => {
      return state.successText
    },
    rules: state => {
      return state.rules
    },
    personalData: state => {
      return state.personalData
    },
    policies: state => {
      return state.policies
    },
    winners: state => {
      return state.winners
    },
    voucherData: state => {
      return state.voucherData
    },
    unregisteredPhone: state => {
      return state.unregisteredPhone
    },
    registeredPhone: state => {
      return state.registeredPhone
    },
    loginOption: state => {
      return state.loginOption
    },
  }
})
