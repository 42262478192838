<template>
  <div>
    <b-modal
      id="closed-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('closed-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h5>
          Акция завершена.
        </h5>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapMutations } from "vuex"

  export default {
    data() {
      return {
      }
    },

    computed: {
      ...mapGetters({
        successText: 'successText'
      }),
    },

    methods: {
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }

  h5 {
    width: 100%;
    color: $blue;
    margin-top: 70px !important;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    font-family: $regular;
    text-align: center;
    padding-bottom: 50px;
  }
}
</style>
