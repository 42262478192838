<template>
  <div class="email-login w-100">
       <form ref="form"
             class="form w-100"
             @submit.stop.prevent="submit"
             style="margin-left: -10px;">
         <b-form-group
           label-for="email-input"
           :invalid-feedback="invalidEmail"
         >
           <b-form-input
             id="email-input"
             placeholder="Введите e-mail"
             v-model="$v.item.email.$model"
             :class="{ 'is-invalid' : isInvalidEmail, 'is-valid' : !isInvalidEmail }"
             :state="validateState($v.item.email)"
             required
           ></b-form-input>

           <div class="input-icon" v-if="$v.item.email.$error || isInvalidEmail">
             <img src="@/assets/svg/incorrect.svg" />
           </div>

           <div class="input-icon" v-if="!$v.item.email.$error && item.email.length > 1 && !isInvalidEmail">
             <img src="@/assets/svg/correct.svg" />
           </div>
        </b-form-group>

        <b-form-group
          style="margin-top: 10px !important;"
          label-for="password-input"
          :invalid-feedback="invalidPassword"
        >
          <b-form-input
            id="password-input"
            placeholder="Введите пароль"
            style="margin-top: 10px !important;"
            v-model="$v.item.password.$model"
            :state="validateState($v.item.password)"
            required
            :class="{ 'is-invalid' : isInvalidPassword, 'is-valid' : !isInvalidPassword }"
            type="password"
          ></b-form-input>

          <div class="input-icon" v-if="$v.item.password.$error || isInvalidPassword">
            <img src="@/assets/svg/incorrect.svg" />
          </div>

          <div class="input-icon" v-if="!$v.item.password.$error && item.password.length > 1 && !isInvalidPassword">
            <img src="@/assets/svg/correct.svg" />
          </div>
       </b-form-group>
       </form>

    <b-button class="button button-orange"
              type="submit"
              onclick="ym(94436170,'reachGoal','send_login')"
              :disabled="disabledBtn"
              @click="submit">Войти</b-button>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

export default {
  name: 'EmailLogin',

  mixins: [validationMixin],

  validations: {
    item: {
      email: {
        required,
        minLength: minLength(4),
        email
      },
      password: {
        required,
      },
    }
  },

  data() {
    return {
      item: {
        email: '',
        password: ''
      },
      isInvalidEmail: false,
      isInvalidPassword: false,
      invalidEmail: 'Некорректное поле',
      invalidPassword: 'Некорректное поле',
    }
  },

  computed: {
    disabledBtn(){
      if ((this.$v.item.$anyError) || (this.item.password.length < 1) || (this.item.email.length < 1))
        return true
    }
  },

  methods: {
    ...mapMutations({
      saveToken: 'saveToken',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText'
    }),

    openEmailLogin(){
      this.emailLogin = true
    },

    putPlaceholder(){
      if (this.item.phone.length < 3){
        this.item.phone = '+79'
      }
    },

    resetForm() {
      this.$v.$reset()

      this.item.phone = ''
    },

    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },

    submit() {
      this.$v.item.$touch()

      if (this.$v.item.$anyError)
        return false

      axios.post('auth/email/login', {
        email: this.item.email,
        password: this.item.password
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
        }
      }).then((response) => {
        this.resetForm()

        this.saveToken(response.data.access.token)
        localStorage.setItem('token', response.data.access.token)
        this.$store.dispatch('getUser')
        // this.$store.dispatch('getCerts')

        this.getSuccessText("Авторизация прошла успешно")
        this.$bvModal.show('success-modal')
        axios.defaults.headers.authorization = "Bearer " + response.data.access.token
      }).catch((error) => {
        this.resetForm()

        this.getErrorText(error.response.data.message)
        this.$bvModal.show('error-modal')
      })
    },
  }
}
</script>

<style scoped lang="scss">
.form-group {
  position: relative;
  font-family: $regular !important;
}

.email-login {
  @media(max-width: 768px){
    max-width: 300px !important;
  }

  @media(max-width: 576px){
    min-width: 260px !important;
    max-width: 260px !important;
  }
}

.form-control {
  color: $darker-grey !important;
  background-color: $white !important;
  border: 1px solid transparent;
  transition: .6s all;
  height: 58px;
  display: flex;
  padding-left: 25px !important;
  align-items: center;
  font-size: 18px;
  margin-top: 35px;
  border-radius: 60px;
  max-width: 340px;
  min-width: 340px;
  font-family: $regular;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid white;
  box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1) !important;

  @media(max-width: 768px){
    margin-left: 0;
  }

  &::placeholder {
    color: #6882B9 !important;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid $darker-blue;
  }

  @media(max-width: 999px){
    min-width: none;
  }
}

.button {
  width: 100%;
  max-width: 340px;
  border-radius: 95px;
  margin-top: 15px;

  @media(max-width: 768px){
    margin-top: 5px;
  }

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.login-option {
  font-family: $regular;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  max-width: 340px;
  text-decoration: underline;
}

.form {

  @media(max-width: 768px){
    margin-left: 0 !important;
    margin-top: 20px;

    .form-control {
      height: 50px;
      max-width: 300px !important;
      min-width: 300px !important;
      margin-top: 0px;

      @media(max-width: 576px){
        min-width: 260px !important;
        max-width: 260px !important;
      }
    }
  }
}
</style>
