<template>
  <div>
    <b-modal
      id="upld-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('upld-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h5>Загрузить чек</h5>
      </div>

      <div class="w-100">
        <div class="buttons-wrapper">
          <b-button class="button button-orange"
                    onclick="ym(94436170,'reachGoal','qr_lk')"
                    @click="openScanner">Сканировать QR-код</b-button>

          <b-button class="button button-orange"
                    onclick="ym(94436170,'reachGoal','hand_main')"
                    @click="openManualUpload">Ввести данные вручную</b-button>

          <label style="z-index: 2;" onclick="ym(94436170,'reachGoal','foto_lk')">
            <input type="file"
                   @change="onDecode"
                   accept="image/jpeg, image/png, image/jpg">

            <span class="button button-orange fake-button">Загрузить фотографию</span>
          </label>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import QrScanner from 'qr-scanner'
import { mapState, mapGetters, mapMutations } from "vuex"

  export default {
    data() {
      return {
        item: {
          fp: '',
          fn: '',
          fd: '',
          date: '',
          time: '',
          s: ''
        },
        files: [],
      }
    },

    computed: {
      ...mapGetters({
        user: 'user'
      }),
    },

    methods: {
      ...mapMutations({
        getSuccessText: 'getSuccessText',
        getErrorText: 'getErrorText',
        saveVoucherDataFp: 'saveVoucherDataFp',
        saveVoucherDataFd: 'saveVoucherDataFd',
        saveVoucherDataFn: 'saveVoucherDataFn',
        saveVoucherDataT: 'saveVoucherDataT',
        saveVoucherDataS: 'saveVoucherDataS',
        saveVoucherDataDate: 'saveVoucherDataDate',
        saveVoucherDataTime: 'saveVoucherDataTime',
        saveVoucherDataFiles: 'saveVoucherDataFiles'
    }),

      saveQrData(){
        this.saveVoucherDataFp(this.item.fp)
        this.saveVoucherDataFn(this.item.fn)
        this.saveVoucherDataFd(this.item.fd)
        this.saveVoucherDataS(this.item.s)
        this.saveVoucherDataT(this.item.t)
        this.saveVoucherDataDate(this.item.date)
        this.saveVoucherDataTime(this.item.time)
      },

      nullData(){
        this.saveVoucherDataFp(null)
        this.saveVoucherDataFn(null)
        this.saveVoucherDataFd(null)
        this.saveVoucherDataS(null)
        this.saveVoucherDataT(null)
        this.saveVoucherDataDate(null)
        this.saveVoucherDataTime(null)

        this.files = [],
        this.item.fp = '',
        this.item.fd = '',
        this.item.fn = '',
        this.item.date = '',
        this.item.time = '',
        this.item.s = ''
      },

      openScanner(){
        this.$bvModal.hide('success-upload-modal')
        this.$bvModal.show('upload-voucher-modal')
      },

      openManualUpload(){
        this.$bvModal.hide('success-upload-modal')
        this.$bvModal.show('cash-voucher-modal')
      },

      logout(){
        this.saveToken(null)
        this.saveUser(null)
        localStorage.removeItem('token')
      },

      onDecode(event) {
        this.nullData()

        this.saveVoucherDataFiles(event.target.files[0])
        QrScanner.scanImage(event.target.files[0])
          .then(result => {
            let newDate

            this.qrData = result.split('&')

            newDate = this.qrData.map(value =>{
              return value.split('=')
            })
            newDate.forEach(value=>{
              if(value[0] === 'fn'){
                this.item.fn = value[1]
              }
              if(value[0] === 'fp'){
                this.item.fp = value[1]
              }
              if(value[0] === 'i'){
                this.item.fd = value[1]
              }
              if(value[0] === 's'){
                this.item.s = value[1]
              }
              if(value[0] === 't'){
                this.t = value[1]
                let chDate = value[1].split('T')[0].split('')
                let chTime = value[1].split('T')[1].split('')

                this.item.date = chDate[6]+chDate[7]+'.'+chDate[4]+chDate[5]+'.'+chDate[0]+chDate[1]+chDate[2]+chDate[3],
                this.item.time = chTime[0]+chTime[1]+':'+chTime[2]+chTime[3]
              }
            })
            let reader = new FileReader();
            reader.onload = (e) => {
              this.files.push({
                src: e.target.result,
                correct: true,
                data: event.target.files[0]
              })
            };
            reader.readAsDataURL(event.target.files[0])
            this.saveQrData()
            this.$bvModal.hide('upld-modal')
            this.$bvModal.show('failed-voucher-modal')
            event.target.value = ''
            this.files = []
          })
          .catch((error) => {
            console.log(error)
            let reader = new FileReader();
            reader.onload = (e) => {
              this.files.push({
                src: e.target.result,
                correct: false,
                data: event.target.files[0]
              })
            }
          reader.readAsDataURL(event.target.files[0])
          this.saveQrData()
          this.$bvModal.hide('upld-modal')
          this.$bvModal.show('failed-voucher-modal')
          event.target.value = ''
          this.files = []
        })
      },

      getCode(){
        axios.post('voix/send', {
          phone: this.user.phone,
        }, {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*",
          }
        }).then((response) => {
          this.$bvModal.show('code-check-modal')
        }).catch((error) => {
          this.$bvModal.show('error-modal')
          this.getErrorText(error.response.data.message)
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;
  text-align: center;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 10px;

    &:hover {
      opacity: .85;
    }
  }

  h3, p {
    font-family: $regular;
    color: white;
  }

  h5 {
    width: 100%;
    color:  $blue;
    margin-top: 40px !important;
    margin-bottom: 0;
    font-weight: 700;
    font-family: $regular;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
  }
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  height: 0;
  z-index: 2;
}

.buttons-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  .button {
    width: 350px;
    font-size: 16px;
    font-family: $regular;
    font-weight: 500;
    margin-bottom: 20px;

    @media(max-width: 768px){
      margin-bottom: 16px;
      max-width: 350px;
    }

    @media(max-width: 370px){
      max-width: 270px;
    }
  }
}

.fake-button {
  @media(max-width: 768px){
    }
}

.upload-option {
  label {
    @media(max-width: 768px){
      width: 100%;
      max-width: 326px;
    }
  }
}

.button-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  @media(max-width: 768px){
    margin-top: 0;
    margin-bottom: 60px;
  }

  .button {
    width: 100%;
    max-width: 350px;
  }
}

.button {
  height: 63px;

  @media(max-width: 768px){
    height: 50px;
  }
}
</style>
