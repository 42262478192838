import Vue from 'vue'
import VueRouter from 'vue-router'
import Office from '../views/Office.vue'
import HomeView from '../views/HomeView.vue'
import WinnersView from '../views/WinnersView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/winners',
    name: 'winners',
    component: WinnersView
  },
  {
    path: '/office',
    name: 'office',
    component: Office
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
