<template>
  <div class="w-100">
    <div class="phone-login w-100"
         v-if="phoneLogin || (this.loginOption === 'phone' && !codeSent)">
      <form ref="form"
            class="modal-form"
            @submit.stop.prevent="submit">
        <b-form-group
          label-for="phone-input"
          v-mask="'+7(###)-###-##-##'"
        >
        <div class="position-relative">
            <b-form-input
              @click="putPlaceholder"
              id="phone-input"
              placeholder="+7 (___) ___-__-__"
              v-model="$v.item.phone.$model"
              maxlength="17"
              :state="validateState($v.item.phone)"
              required
            ></b-form-input>

            <div class="input-icon bottom" v-if="$v.item.phone.$error || isErrorPhone">
              <img src="@/assets/svg/incorrect.svg" />
            </div>

            <div class="input-icon bottom" v-if="!$v.item.phone.$error && item.phone.length > 5 && !isErrorPhone">
              <img src="@/assets/svg/correct.svg" />
            </div>
          </div>

          <div v-if="isErrorPhone" class="error">
            {{ errorPhone }}
          </div>
        </b-form-group>
      </form>

      <b-button class="button button-orange"
                type="submit"
                onclick="ym(94436170,'reachGoal','cod_tel')"
                :disabled="disabledBtn"
                @click="submit">Получить код</b-button>
    </div>

    <div v-show="codeSent"
         style="margin-top: 45px;">
      <div style="display: flex; justify-content: center;">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator=""
          :num-inputs="4"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-complete="handleOnComplete"
        />
      </div>

      <div class="login-option mt-4 login-code"
           v-show="logged">Готово</div>

      <div v-show="codeError"
           class="error">{{ codeErrorResponse }}</div>

      <div class="login-option mt-4 login-code"
           @click="resubmit"
           id="timer"
           v-show="!logged || !employee">Получить новый код можно через 120 секунд</div>
    </div>

    <div>
      <EmailLogin v-show="emailLogin && (this.loginOption === 'email' && !codeSent)" />
    </div>

    <div class="login-option"
         v-show="emailLogin && (this.loginOption === 'email' && !codeSent)"
         @click="openLoginOption('phone')">Войти через СМС-код</div>

    <div class="login-option"
         v-show="phoneLogin || (this.loginOption === 'phone' && !codeSent)"
         @click="openLoginOption('email')">Войти по логину и паролю</div>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import EmailLogin from '@/components/EmailLogin'
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

export default {
  name: 'Login',

  mixins: [validationMixin],

  validations: {
    item: {
      phone: {
        required,
        minLength: minLength(17)
      },
    }
  },

  components: {
    EmailLogin
  },

  data() {
    return {
      item: {
        phone: '',
      },
      code: '',
      invalidPhone: '',
      isInvalidPhone: false,
      emailLogin: false,
      phoneLogin: true,
      codeSent: false,
      codeError: false,
      codeErrorResponse: '',
      logged: '',
      errorPhone: 'Некорректное поле',
      isErrorPhone: false,
      seconds: 119,
      employee: false
    }
  },

  computed: {
    ...mapGetters({
      registeredPhone: 'registeredPhone',
      loginOption: 'loginOption'
    }),

    disabledBtn(){
      if ((this.$v.item.$anyError) || (this.item.phone.length < 17))
        return true
    }
  },

  watch: {
    registeredPhone(){
      if(this.registeredPhone.length > 1){
        this.item.phone = this.registeredPhone
      }
    }
  },

  mounted(){
    this.item.phone = this.registeredPhone
  },

  methods: {
    ...mapMutations({
      saveToken: 'saveToken',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText',
      saveLoginOption: 'saveLoginOption',
      saveUnregisteredPhone: 'saveUnregisteredPhone'
    }),

    setCountDown() {
      const timerVal = document.getElementById('timer')

      const timer = setInterval(() => {
       if (this.seconds < 1) {
         timerVal.textContent = 'Получить новый код'
         timerVal.style.ponterEvents = 'auto'
         timerVal.style.cursor = 'pointer'
         clearInterval(timer)
       }
       timerVal.textContent = `Получить новый код можно через ${this.seconds} секунд`
       timerVal.style.ponterEvents = 'none'
       timerVal.style.cursor = 'not-allowed'
       this.seconds -= 1;

       if (this.seconds < 1) {
         timerVal.textContent = 'Получить новый код'
         timerVal.style.ponterEvents = 'auto'
         timerVal.style.cursor = 'pointer'
         clearInterval(timer)
       }
     }, 1000)
    },

    openLoginOption(option){
      this.emailLogin = !this.emailLogin
      this.phoneLogin = !this.phoneLogin

      this.saveLoginOption(option)
    },

    putPlaceholder(){
      if (this.item.phone.length < 3){
        this.item.phone = '+79'
      }
    },

    handleOnComplete(value) {
      this.code = value

      axios.post('auth/phone/login', {
        phone: this.item.phone,
        code: this.code
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
        }
      }).then((response) => {
        this.saveToken(response.data.access.token)
        localStorage.setItem('token', response.data.access.token)
        this.$store.dispatch('getUser')

        this.logged = true

        // this.getSuccessText("Авторизация прошла успешно")
        // this.$bvModal.show('success-modal')

        axios.defaults.headers.authorization = "Bearer " + response.data.access.token
      }).catch((error) => {
        if (error.response.data.message == "Пользователя с таким номером телефона не существует."){
          this.saveUnregisteredPhone(this.item.phone)
        } else {
          this.codeError = true
          this.codeErrorResponse = error.response.data.message

          if (error.response.data.message == "Согласно правилам Акции, сотрудники компании Алкон не могут принимать участие в Акции"){
            this.employee = true

            this.getErrorText(error.response.data.message)
            this.$bvModal.show('error-modal')
          }
        }
      })
    },

    resetForm() {
      this.$v.$reset()

      this.item.phone = ''
      this.errorPhone = 'Некорректное поле'
    },

    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },

    resubmit(){
      this.seconds = 119
      this.setCountDown()

      axios.post('auth/phone/sms', {
        phone: this.item.phone,
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Cache-Control': "no-cache",
          'Access-Control-Allow-Origin': "*"
        }
      }).then((response) => {
        this.emailLogin = false
        this.phoneLogin = false
        this.codeSent = true

        this.errorPhone = 'Некорректное поле'
        this.isErrorPhone = false
      }).catch((error) => {
        this.isErrorPhone = true
        this.errorPhone = error.response.data.error.phone
      })
    },

    submit() {
      this.$v.item.$touch()
      this.seconds = 119
      this.setCountDown()

      if (this.$v.item.$anyError)
        return false

      axios.post('auth/phone/sms', {
        phone: this.item.phone,
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Cache-Control': "no-cache",
          'Access-Control-Allow-Origin': "*"
        }
      }).then((response) => {
        this.emailLogin = false
        this.phoneLogin = false
        this.codeSent = true

        this.errorPhone = 'Некорректное поле'
        this.isErrorPhone = false
      }).catch((error) => {
        this.isErrorPhone = true
        this.errorPhone = error.response.data.message
      })
    },
  }
}
</script>

<style scoped lang="scss">
.form-group {
  position: relative;
  font-family: $regular !important;
}

.form-control {
  color: $darker-grey !important;
  background-color: $white !important;
  border: 1px solid transparent;
  transition: .6s all;
  height: 58px;
  display: flex;
  padding-left: 25px !important;
  align-items: center;
  font-size: 18px;
  margin-top: 35px;
  border-radius: 60px;
  max-width: 340px;
  font-family: $regular;
  border: 2px solid white;
  box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1) !important;

  @media(max-width: 768px){
    height: 42px;
    font-size: 14px;
  }

  &::placeholder {
    color: #6882B9 !important;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid $darker-blue;
  }
}

.button {
  width: 100%;
  max-width: 340px;
  border-radius: 95px;
  margin-top: 15px;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.login-option {
  font-family: $regular;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
}

.error {
  margin-top: 5px;
  width: 100%;
  position: absolute;
  max-width: 270px;
  text-align: left;
  padding-left: 15px;
  font-size: 9px;
  color: white;
  font-family: $regular;
  line-height: 110%;
  text-align: center;

  @media(max-width: 450px){
    max-width: 240px;
  }
}
</style>
