import { render, staticRenderFns } from "./SuccessUpload.vue?vue&type=template&id=36bc2814&scoped=true"
import script from "./SuccessUpload.vue?vue&type=script&lang=js"
export * from "./SuccessUpload.vue?vue&type=script&lang=js"
import style0 from "./SuccessUpload.vue?vue&type=style&index=0&id=36bc2814&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bc2814",
  null
  
)

export default component.exports