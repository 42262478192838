<template>
  <div>
    <b-modal
      id="upload-voucher-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('upload-voucher-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <p>
          Пожалуйста, наведите камеру на QR-код
        </p>
      </div>

      <div class="content">
        <qrcode-stream @decode="onDecode"></qrcode-stream>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapMutations } from "vuex"

  export default {
    data() {
      return {
        qrData: '',
        result: '',
        fp: '',
        fn: '',
        fd: '',
        t: '',
        s: '',
        date: '',
        time: '',
        sent: false
      }
    },

    computed: {
      ...mapGetters({
        user: 'user'
      }),
    },

    methods: {
      ...mapMutations({
        saveToken: 'saveToken',
        getSuccessText: 'getSuccessText',
        getErrorText: 'getErrorText',
        saveVoucherDataFp: 'saveVoucherDataFp',
        saveVoucherDataFd: 'saveVoucherDataFd',
        saveVoucherDataFn: 'saveVoucherDataFn',
        saveVoucherDataT: 'saveVoucherDataT',
        saveVoucherDataS: 'saveVoucherDataS',
        saveVoucherDataDate: 'saveVoucherDataDate',
        saveVoucherDataTime: 'saveVoucherDataTime',
      }),

      onDecode(decodedString) {
        this.result = decodedString

        let newDate
        this.qrData = decodedString.split('&')

        newDate = this.qrData.map(value => {
          return value.split('=')
        })

        newDate.forEach(value => {
          if(value[0] === 'fn'){
            this.fn = value[1]
          }
          if(value[0] === 'fp'){
            this.fp = value[1]
          }
          if(value[0] === 'i'){
            this.fd = value[1]
          }
          if(value[0] === 's'){
            this.s = value[1]
          }
          if(value[0] === 't'){
            this.t = value[1]
            let chDate = value[1].split('T')[0].split('')
            let chTime = value[1].split('T')[1].split('')

            this.date = chDate[6]+chDate[7]+'.'+chDate[4]+chDate[5]+'.'+chDate[0]+chDate[1]+chDate[2]+chDate[3],
            this.time = chTime[0]+chTime[1]+':'+chTime[2]+chTime[3]
          }

          this.saveQrData()
        })

        this.submitReceipt()
      },

      saveQrData(){
        this.saveVoucherDataFp(this.fp)
        this.saveVoucherDataFn(this.fn)
        this.saveVoucherDataFd(this.fd)
        this.saveVoucherDataS(this.s)
        this.saveVoucherDataT(this.t)
        this.saveVoucherDataDate(this.date)
        this.saveVoucherDataTime(this.time)
      },

      closeModal(){
        this.$bvModal.hide('upload-voucher-modal')

        this.s = '',
        this.fp = '',
        this.fn = '',
        this.fd = '',
        this.date = '',
        this.time = ''

        this.saveQrData()
      },

      submitReceipt() {
        const formData = new FormData()
        formData.append('fp', this.fp)
        formData.append('fn', this.fn)
        formData.append('fd', this.fd)
        formData.append('datetime', this.date + ' ' + this.time)
        formData.append('price', this.s)

        axios.post('profile/receipts', formData, {
        }, {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*",
            'Authorization': "Bearer " + localStorage.getItem('token')
          }
        }).then((response) => {
          this.$bvModal.hide('upload-voucher-modal')
          this.$bvModal.show('success-upload-modal')
        }).catch((error) => {
          if (error.response.data.message === "Время жизни сессии истекло. Пожалуйста, авторизуйтесь"){
            this.getErrorText(error.response.data.message)

            this.logout()
            this.$bvModal.hide('upload-voucher-modal')
            this.$bvModal.show('error-modal')
          }
          
          if (error.response.data.message === "Увы, по правилам Акции каждый участник может загрузить не более 3 (трех) чеков в сутки."){
            this.getErrorText(error.response.data.message)

            this.$bvModal.hide('upload-voucher-modal')
            this.$bvModal.show('error-modal')
          } else {
            this.$bvModal.hide('upload-voucher-modal')
            this.$bvModal.show('failed-voucher-modal')
          }
        })
      },

      getCode(){
        axios.post('voix/send', {
          phone: this.user.phone,
        }, {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*",
          }
        }).then((response) => {
          this.$bvModal.show('code-check-modal')
          this.$bvModal.hide('upload-voucher-modal')
        }).catch((error) => {
          this.$bvModal.show('error-modal')
          this.getErrorText(error.response.data.message)
        })
      },
    },
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }

  p {
    width: 100%;
    color: $blue;
    margin-top: 40px !important;
    margin-bottom: 30px;
    font-family: $regular;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    padding-bottom: 0px;
    line-height: 120%;
  }
}

.content {
  padding: 20px;
  padding-top: 0;

  @media(max-width: 768px){
    padding: 15px;
    padding-top: 0;
  }
}
</style>
