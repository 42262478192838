<template>
  <section class="section-wrapper" 
           :class="{ bgspecial : (unregisteredPhone && !registeredPhone) }"
           id="mechanics">
    <div class="content-wrapper desktop">
      <div class="opacity-bg">
      </div>

      <div class="action">
        <div class="d-flex mb-3 action-wrapper action-wrapper__registration"
             v-if="((!user && registration) || (!user && unregisteredPhone)) && !registeredPhone">
          <div class="reg-opt-wrapper">
            <div class="options">
              <span @click="openLogin" style="">Авторизация</span>

              <span @click="openRegistration" class="active">Регистрация</span>
            </div>

            <PhoneLogin v-if="!unregisteredPhone" />

            <Registration v-if="unregisteredPhone" />
          </div>
        </div>

        <div class="d-flex mb-3 action-wrapper"
             v-if="(!user && (login || registeredPhone) && !unregisteredPhone)">
          <div class="reg-opt-wrapper">
            <div class="options">
              <span @click="openLogin" class="active">Авторизация</span>

              <span @click="openRegistration" 
                    :class="{ disabled : registeredPhone}"
              >
                Регистрация
              </span>
            </div>

            <PhoneLogin />
          </div>
        </div>

        <div class="d-flex mb-3 action-wrapper action-wrapper__upload"
             v-if="user">
             <p class="w-100 text-center upld-title">Загрузка чека</p>

            <!-- <div style="margin-top: 40px; font-size: 16px; text-align: center;">Акция завершена</div> -->

            <div class="d-flex step step__3 step__upload">
              <div style="opacity: 1 !important;"
                   class="w-100">
                <div class="upload-options">
                  <div class="upload-option">
                    <button class="button button-orange"
                            onclick="ym(94436170,'reachGoal','qr_main')"
                            v-b-modal.closed-modal>
                      Сканировать QR-код
                    </button>
                  </div>

                  <div class="upload-option mb-3">
                    <button class="button button-orange"
                            onclick="ym(94436170,'reachGoal','hand_main')"
                            v-b-modal.closed-modal>
                      Ввести данные вручную
                    </button>
                  </div>

                  <!-- <div class="upload-option">
                    <label style="z-index: 2;" onclick="ym(94436170,'reachGoal','foto_main')">
                      <input type="file"
                             @change="onDecode"
                             id="fileInput"
                             value=""
                             accept="image/jpeg, image/png, image/jpg">

                      <span class="button button-orange fake-button" 
                            style="text-transform: none;">
                          Загрузить фотографию
                      </span>
                    </label>
                  </div> -->
                </div>
              </div>

              <div>
                <p class="sub-title-text">
                  Обратите внимание:
                </p>

                <p>
                  1. На фотографии чека должен чётко <br> читаться QR-код; <br>
                  2. Файл должен быть в формате JPEG или <br> PNG, размером не более 20мБ
                </p>
              </div>
            </div>
        </div>
      </div>

      <div class="steps">
        <h3 class="section-title">3 простых шага <br> для участия</h3>

        <div class="d-flex step step__1 mb-3">
          <div class="number-icon">1</div>

          <img loading="lazy" src="@/assets/svg/step-1@wide.svg" class="wide" />

          <img loading="lazy" src="@/assets/svg/step-1.svg" class="normal" style="margin-top: 8px;" />

          <div>
            Авторизуйтесь <br>
            или <br class="tablet"> зарегистрируйтесь

            <p class="subtext-small">
              в Клубе привилегий «МоиГлаза»
            </p>
          </div>
        </div>

        <div class="d-flex step step__2 halfopacity"
             :class="{ opacity : user }">
          <div class="number-icon">2</div>

          <img loading="lazy" src="@/assets/svg/step-2@wide.svg" class="wide" style="margin-right: 5px;" />

          <img loading="lazy" src="@/assets/svg/step-2.svg" class="normal" />

          <div>
            загрузите чек <br class="tablet"> о покупке ЛИНЗ

            <p class="subtext-small">
              TOTAL30, TOTAL1 или PRECISION1 <br>
              с 3 июня по 31 августа 2024 года
            </p>
          </div>
        </div>

        <div class="d-flex step step__4 halfopacity">
          <div class="number-icon">3</div>

          <div>
            Выигрывайте <br> в розыгрыше <br class="tablet"> призов!
          </div>
        </div>
      </div>
    </div>

    <div class="content-wrapper mobile">
      <h3 class="section-title">3 простых шага <br> для участия</h3>

      <div class="steps">
        <div class="d-flex step step__1 mb-3">
          <div class="number-icon">1</div>

          <img loading="lazy" src="@/assets/svg/step-1.svg" class="normal" />

          <div>
            Авторизуйтесь <br>
            или <br> зарегистрируйтесь

            <p class="subtext-small">
              в Клубе привилегий «МоиГлаза»
            </p>
          </div>
        </div>

        <div class="d-flex step step__2 halfopacity"
             :class="{ opacity : user }">
          <div style="margin-bottom: -10px;" class="column-text">
            <div class="d-flex">
              <div class="number-icon">2</div>

              <img loading="lazy" src="@/assets/svg/step-2@wide.svg" class="wide" style="margin-right: 5px;" />

              <img loading="lazy" src="@/assets/svg/step-2.svg" class="normal" />

              <div>
                загрузите чек <br class="tablet"> о покупке ЛИНЗ

                <p class="subtext-small">
                  TOTAL30, TOTAL1 или PRECISION1 <br>
                  с 3 июня по 31 августа 2024 года
                </p>
              </div>
            </div>

            <div class="d-flex mobile-step-3" :class="{ mbspecial : (unregisteredPhone && !user) }">
              <div class="number-icon">3</div>

              <div>
                Выигрывайте <br> в розыгрыше <br> призов!
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex mb-3 action-wrapper" 
             :class="{ 'action-wrapper__registration' : unregisteredPhone}"
             v-if="((!user && registration) || (!user && unregisteredPhone)) && !registeredPhone">
          <div class="reg-opt-wrapper">
            <div class="options">
              <span @click="openLogin">Авторизация</span>

              <span @click="openRegistration" class="active">Регистрация</span>
            </div>

            <PhoneLogin v-if="!unregisteredPhone" />

            <Registration v-if="unregisteredPhone" />
          </div>
        </div>

        <div class="d-flex mb-3 action-wrapper"
             :class="{ 'margin-top-special' : registeredPhone}"
             v-if="(!user && (login || registeredPhone) && !unregisteredPhone)">
          <div class="reg-opt-wrapper">
            <div class="options">
              <span @click="openLogin" class="active">Авторизация</span>

              <span @click="openRegistration" 
                    :class="{ disabled : registeredPhone}"
              >
                Регистрация
              </span>
            </div>

            <PhoneLogin />
          </div>
        </div>

        <div class="action"
             :class="{ 'margin-top-special' : registeredPhone}">
          <div class="d-flex mb-3 action-wrapper action-wrapper__upload"
               v-if="user">
            <div>
              <p class="w-100 text-center check-title upld-title">Загрузка чека</p>

              <!-- <div style="margin-top: 40px; font-size: 16px; text-align: center;">Акция завершена</div> -->

              <div class="d-flex step step__3 step__upload">
                <div style="opacity: 1 !important;"
                     class="w-100">

                  <div class="upload-options">
                    <div class="upload-option">
                      <button class="button button-orange"
                              v-b-modal.closed-modal>
                        Сканировать QR-код
                      </button>
                    </div>

                    <div class="upload-option mb-3">
                      <button class="button button-orange"
                              v-b-modal.closed-modal>
                        Ввести данные вручную
                      </button>
                    </div>

                    <!-- <div class="upload-option">
                      <label style="z-index: 2;">
                        <input type="file"
                               @change="onDecode"
                               accept="image/jpeg, image/png, image/jpg">

                        <span class="button button-orange">Загрузить фотографию</span>
                      </label>
                    </div> -->
                  </div>
                </div>

                <div>
                  <p class="sub-title-text">
                    Обратите внимание:
                  </p>

                  <p>
                    1. На фотографии чека должен чётко <br> читаться QR-код; <br>
                      2. Файл должен быть в формате JPEG <br> или  PNG, размером не более 20мБ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import QrScanner from 'qr-scanner'
import PhoneLogin from '@/components/PhoneLogin'
import Registration from '@/components/Registration'
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: 'Mechanics',

  data: () => ({
    item: {
      fp: '',
      fn: '',
      fd: '',
      date: '',
      time: '',
      s: ''
    },
    files: [],
    login: true,
    registration: false,
  }),

  components: {
    PhoneLogin,
    Registration,
  },

  computed: {
    ...mapGetters({
      user: 'user',
      registeredPhone: 'registeredPhone',
      unregisteredPhone: 'unregisteredPhone'
    }),
  },

  methods: {
    ...mapMutations({
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText',
      saveVoucherDataFp: 'saveVoucherDataFp',
      saveVoucherDataFd: 'saveVoucherDataFd',
      saveVoucherDataFn: 'saveVoucherDataFn',
      saveVoucherDataT: 'saveVoucherDataT',
      saveVoucherDataS: 'saveVoucherDataS',
      saveVoucherDataDate: 'saveVoucherDataDate',
      saveVoucherDataTime: 'saveVoucherDataTime',
      saveVoucherDataFiles: 'saveVoucherDataFiles',
      saveLoginOption: 'saveLoginOption'
    }),

    openLogin(){
      this.login = true
      this.registration = false
    },

    openRegistration(){
      this.registration = true
      this.login = false
      
      this.saveLoginOption('phone')
    },

    saveQrData(){
      this.saveVoucherDataFp(this.item.fp)
      this.saveVoucherDataFn(this.item.fn)
      this.saveVoucherDataFd(this.item.fd)
      this.saveVoucherDataS(this.item.s)
      this.saveVoucherDataT(this.item.t)
      this.saveVoucherDataDate(this.item.date)
      this.saveVoucherDataTime(this.item.time)
    },

    nullData(){
        this.saveVoucherDataFp(null)
        this.saveVoucherDataFn(null)
        this.saveVoucherDataFd(null)
        this.saveVoucherDataS(null)
        this.saveVoucherDataT(null)
        this.saveVoucherDataDate(null)
        this.saveVoucherDataTime(null)

        this.files = [],
        this.item.fp = '',
        this.item.fd = '',
        this.item.fn = '',
        this.item.date = '',
        this.item.time = '',
        this.item.s = ''
      },

    logout(){
      this.saveToken(null)
      this.saveUser(null)
      localStorage.removeItem('token')
    },

    onDecode(event) {
      this.nullData()

      this.saveVoucherDataFiles(event.target.files[0])
      
      QrScanner.scanImage(event.target.files[0])
        .then(result => {
          let newDate

          this.qrData = result.split('&')

          newDate = this.qrData.map(value =>{
            return value.split('=')
          })
          newDate.forEach(value=>{
            if(value[0] === 'fn'){
              this.item.fn = value[1]
            }
            if(value[0] === 'fp'){
              this.item.fp = value[1]
            }
            if(value[0] === 'i'){
              this.item.fd = value[1]
            }
            if(value[0] === 's'){
              this.item.s = value[1]
            }
            if(value[0] === 't'){
              this.t = value[1]
              let chDate = value[1].split('T')[0].split('')
              let chTime = value[1].split('T')[1].split('')

              this.item.date = chDate[6]+chDate[7]+'.'+chDate[4]+chDate[5]+'.'+chDate[0]+chDate[1]+chDate[2]+chDate[3],
              this.item.time = chTime[0]+chTime[1]+':'+chTime[2]+chTime[3]
            }
          })
          let reader = new FileReader();
          reader.onload = (e) => {
            this.files.push({
              src: e.target.result,
              correct: true,
              data: event.target.files[0]
            })
          };
          reader.readAsDataURL(event.target.files[0])
          this.saveQrData()
          this.$bvModal.show('failed-voucher-modal')
          event.target.value = ''
          this.files = []
        })
        .catch((error) => {
          console.log(error)
          let reader = new FileReader();
          reader.onload = (e) => {
            this.files.push({
              src: e.target.result,
              correct: false,
              data: event.target.files[0]
            })
          }
        reader.readAsDataURL(event.target.files[0])
        this.saveQrData()
        this.$bvModal.show('failed-voucher-modal')
        event.target.value = ''
        this.files = []
      })
    },

    getCode(){
      axios.post('voix/send', {
        phone: this.user.phone,
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
        }
      }).then((response) => {
        this.$bvModal.show('code-check-modal')
      }).catch((error) => {
        this.$bvModal.show('error-modal')
        this.getErrorText(error.response.data.message)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-repeat: no-repeat;
  padding-top: 52px;
  padding-bottom: 52px;
  background-position: center center;
  background-size: 100% 100%;
  color: $white;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url('@/assets/mechanics-bg.webp');

  @media(max-width: 1200px){
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(max-width: 999px){
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 52px;
    padding-bottom: 52px;
    border-radius: 40px;
    width: calc(100vw - 10px);
    margin-left: 5px;
    background-position: center left -140px;
    background-size: cover;
  }

  @media(max-width: 900px){
    padding-left: 25px;
    padding-right: 25px;
    background-position: center left -160px;
  }

  @media(max-width: 800px){
    background-position: center left -220px;
  }

  @media(max-width: 756px){
    background-size: cover;
    padding: 30px 10px;
    padding-top: 70px;
    background-image: url('@/assets/mechanics-bg@mobile.webp');
    background-position: center center;
    background-size: 100% 100%;
    width: calc(100vw - 20px);
    margin-left: 10px;
  }

  @media(max-width: 576px){
    padding: 30px 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    max-height: 1000px;
  }

  @media(max-width: 350px){
    padding-bottom: 0px;
  }
}

.section-title {
  text-align: left;
  color: $blue;
  margin-bottom: 60px;

  @media(max-width: 1200px){
    font-size: 30px;
  }

  @media(max-width: 576px){
    margin-bottom: 20px;
    font-size: 26px;
  }
}

.content-wrapper {
  font-size: 24px;
  border-radius: 40px;
  position: relative;
  max-width: 1700px;

  @media(max-width: 1600px){
    max-width: 1300px;
  }

  @media(max-width: 1400px){
    max-width: 1130px;
  }

  @media(max-width: 1200px){
    max-width: 880;
  }

  @media(max-width: 999px){
    max-width: none;
  }

  @media(max-width: 768px){
    font-size: 16px;
    margin-top: 30px;
  }
}

.opacity-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(33, 142, 188, 0.4) 0%, rgba(33, 142, 188, 0) 10.44%), linear-gradient(0deg, rgba(33, 142, 188, 0.3), rgba(33, 142, 188, 0.3)), #EEF3F5;
  box-shadow: 0px 0px 26.8px rgba(0, 48, 135, 0.1);
  opacity: 0.3;
  border-radius: 40px;
  z-index: 1;
  filter: brightness(400%);

  @media(max-width: 999px){
    display: none;
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 60px;

  .fake-button {
    @media(max-width: 768px){
      margin-top: 10px;
    }
  }

  .fake-btn {
    background-color: $orange;
    color: $white;
    font-family: $regular;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 32px;
    padding: 10px 50px;
    border-radius: 94px;
    text-align: center;

    @media(max-width: 768px){
      font-size: 18px;
      max-width: 270px;
    }
  }
}

.steps {
  margin-right: 0px;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-right: 120px;
  color: $blue;
  position: relative;
  z-index: 5;

  @media(max-width: 1600px){
  }

  @media(max-width: 1400px){
    padding-right: 80px;
  }

  @media(max-width: 1100px){
    padding-right: 25px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media(max-width: 820px){
    padding-right: 0;
  }

  @media(max-width: 768px){
    margin-right: 0;
  }

  @media(max-width: 756px){
    margin-right: 0;
    padding-bottom: 0;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.step {
  display: flex;
  justify-content: flex-start;

  @media(max-width: 756px){
    width: 280px;
  }

  .number {
    background-color: $white;
    color: $darker-blue;
    border-radius: 50%;
    margin-right: 15px;
    margin-left: 4px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 24px;
    max-height: 24px;
    font-size: 14px;
    margin-bottom: 0 !important;
    margin-top: 5px !important;
  }

  img {
    margin-right: 30px;
    flex-shrink: 0;

    @media(max-width: 1600px){
      margin-right: 17px;
    }

    @media(max-width: 768px){
      margin-left: 0;
      margin-right: 9px;
    }
  }

  div {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 32px;
    font-family: $regular;
    text-align: left;
    line-height: 118%;

    @media(max-width: 1600px){
      font-size: 24px;
    }

    @media(max-width: 1200px){
      font-size: 20px;
    }

    @media(max-width: 756px){
      justify-content: flex-start;
      text-align: left;
      font-size: 17px;
      width: 100%;
      line-height: 110%;
    }
  }

  &__1 {
    div {

      @media(max-width: 768px){
        margin-top: 0;
      }
    }
  }

  &__2 {
    .normal {
      @media(max-width: 576px){
        max-height: 170px;
      }
    }

    div {

      &:first-child {
        margin-bottom: 35px;
      }

      @media(max-width: 768px){
        margin-top: 0;

        &:first-child {
          margin-bottom: 16px;
        }
      }

      @media(max-width: 576px){
        margin-top: 0;

        &:first-child {
          margin-bottom: 4px;
        }
      }
    }
  }

  &__3 {
    div {
      opacity: 1;
      margin-top: 6px;

      @media(max-width: 768px){
        margin-top: 11px;
      }
    }
  }

  &__4 {
    div {
      margin-top: -60px !important;

      &:nth-child(2){
        margin-left: 32px;

        @media(max-width: 1400px){
          margin-left: 17px;
        }
      }
    }
  }

  &__upload {
    flex-direction: column;
    text-align: left;

    div {
      opacity: 1;
      margin-top: 3px;
      text-align: left;
      font-size: 16px;
      font-family: $regular;
      text-transform: none;
      font-weight: 400;
      line-height: 125%;

      @media(max-width: 999px){
        font-size: 14px;
        line-height: 140%;
      }

      p {
        margin-bottom: 0;
        font-weight: 400;
        color: $white;
      }

      .sub-title-text {
        font-weight: 600;
        margin-bottom: 5px;
      }
    }
  }

  &__4 {
    div {
      @media(max-width: 768px){
        line-height: 19px;
        margin-top: 20px;
      }
    }
  }
}

.mechanics-decor {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 60px;
  margin-bottom: 165px;

  @media(max-width: 1300px){
    max-width: 300px;
  }

  @media(max-width: 1100px){
    max-width: 220px;
  }

  @media(max-width: 999px){
    display: none;
  }
}

.mobile {
  display: none !important;

  @media(max-width: 756px){
    display: block !important;
  }
}

.desktop {
  display: flex !important;

  @media(max-width: 756px){
    display: none !important;
  }
}

.options {
  display: flex;
  justify-content: center;
  background-color: $white;
  border-radius: 30px;
  color: $blue;
  height: 45px;

  @media(max-width: 1300px){
    max-width: 340px;
    width: 100%;
  }

  @media(max-width: 876px){
    max-width: 290px;
  }

  span {
    cursor: pointer;
    text-transform: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    font-family: $regular;
    height: 45px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 0 10px;
    border-radius: 30px;
    color: #565555;

    @media(max-width: 1200px){
      font-size: 18px;
    }

    &.active {
      background-color: $blue;
      color: $white;
    }

    &:hover {
      opacity: .9;
    }

    @media(max-width: 1050px){
      font-size: 18px;
      font-weight: 500;
    }

    @media(max-width: 576px){
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.upload-options {
  width: 100%;
  margin-top: 10px !important;
  margin-left: 0 !important;

  @media(max-width: 999px){
    margin-bottom: 40px;
  }

  @media(max-width: 768px){
    margin-top: 25px !important;
  }

  .upload-option {
    display: flex;
    width: 100%;
    margin-bottom: 5px;

    @media(max-width: 999px){
      width: fit-content;
    }

    @media(max-width: 756px){
      flex-direction: column;
      align-items: center;
    }

    .button {
      height: 51px;
      width: 100%;
      max-width: 326px;
      min-width: 326px;
      z-index: 2;
      font-size: 17px;
      text-transform: uppercase !important;

      @media(max-width: 999px){
        font-size: 14px;
      }

      @media(max-width: 768px){
        height: 51px;
        max-width: 286px;
        min-width: 286px;
      }

      @media(max-width: 500px){
        font-size: 14px;
        white-space: nowrap;
        max-width: 256px;
        min-width: 256px;
      }
    }

    .upload-tip {
      font-size: 13px;
      font-family: $regular;
      height: 65px;
      display: flex;
      align-items: center;
      border-radius: 96px;
      background-color: #0496dc;
      margin-left: -60px;
      margin-top: 0;
      z-index: 1;
      padding-left: 90px;
      padding-right: 35px;

      @media(max-width: 1200px){
        font-size: 11px !important;
      }

      @media(max-width: 768px){
        height: 70px;
        max-width: 290px;
        min-width: 290px;
        margin-left: 0;
        padding: 0;
        padding-top: 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        justify-content: flex-start;
        padding-left: 16px;
        margin-top: -20px;
        font-size: 9px !important;
      }

      @media(max-width: 420px){
        max-width: 250px;
        min-width: 250px;
        font-size: 14px;
        white-space: nowrap;
      }

      &__long {
        @media(max-width: 768px){
          margin-top: -30px;
          height: 90px;
          padding-right: 30px;
          padding-top: 30px;
          padding-bottom: 10px;
        }
      }

      p {
        margin-bottom: 0;
        white-space: nowrap;
      }

      .long-tip {
        @media(max-width: 768px){
          white-space: normal;
        }
      }
    }
  }
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  height: 0;
  z-index: 2;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 1600px){
  }
}

.action {
  margin-left: 12%;
  position: relative;
  z-index: 5;

  @media(max-width: 1600px){
    margin-left: 130px;
  }

  @media(max-width: 1400px){
    margin-left: 80px;
  }

  @media(max-width: 1100px){
    margin-left: 25px;
  }

  @media(max-width: 888px){
    margin-left: 0px;
  }

  @media(max-width: 768px){
    margin-top: 15px !important;
  }

  @media(max-width: 756px){
    display: flex;
    justify-content: center;
  }
}

.action-wrapper {
  border-radius: 30px;
  padding: 50px 30px;
  width: 100%;
  justify-content: center;
  min-width: 340px;

  @media(max-width: 820px){
    min-width: 285px;
  }

  @media(max-width: 756px){
    padding-top: 32px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px !important;
  }

  @media(max-width: 576px){
    padding: 0;
    max-width: 260px;
    min-width: 260px;
    margin-top: 30px !important;
    padding-bottom: 60px;

    div {
      max-width: 260px;
      min-width: 260px;
    }
  }

  &__upload {
    padding-top: 25px;
    padding-bottom: 25px;
    flex-direction: column;

    @media(max-width: 768px){
      padding-top: 32px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 0;
    }

    @media(max-width: 756px){
      padding-top: 0;
      padding-right: 0;
      margin-top: 120px;
      padding-left: 0;
    }

    @media(max-width: 576px){
      padding-top: 0;
      padding-right: 0;
      margin-top: 0px !important;
      padding-left: 0;
    }
  }

  &__registration {
    padding-bottom: 10px;
    padding-top: 25px;

    @media(max-width: 1100px){
      padding-left: 0;
    }

    @media(max-width: 756px){
      align-items: center;
    }

    @media(max-width: 576px){
      margin-top: 30px;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.margin-top-special {
  @media(max-width: 576px){
    margin-top: 60px !important;
  }
}

.halfopacity {
  opacity: 0.5;
}

.column-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.opacity {
  opacity: 1;
}

.check-title {
  text-transform: none;
  font-size: 18px;
}

.upld-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: $white;
  margin-bottom: 47px;

  @media(max-width: 999px){
    margin-bottom: 25px;
  }

  @media(max-width: 756px){
    padding-top: 50px;
  }

  @media(max-width: 576px){
    padding-top: 20px;
  }
}

.wide {
  display: none;

  @media(max-width: 1600px){
    display: none;
  }
}

.normal {

  @media(max-width: 1600px){
    display: block;
  }
}

.mobile-step-3 {
  div {
    margin-top: -75px !important;

    &:nth-child(2){
      margin-left: 10px;
    }
  }

  @media(max-width: 576px){
      margin-bottom: 100px;
  }
}

.reg-opt-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.number-icon {
  background-color: $darker-blue;
  color: $white;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  max-width: 36px;
  max-height: 36px;
  text-align: center;
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 5px;
  font-size: 20px !important;

  @media(max-width: 756px){
    width: 28px;
    height: 28px;
    max-width: 28px;
    max-height: 28px;
    font-size: 16px !important;
  }
}

.subtext-small {
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-transform: none;
  color: $darker-grey;
  margin-top: 7px;

  @media(max-width: 820px){
    font-size: 14px;
  }

  @media(max-width: 576px){
    font-size: 12px;
  }
}

.tablet {
  display: none;

  @media(max-width: 820px){
    display: block !important;
  }

  @media(max-width: 756px){
    display: none !important;
  }
}

.mbspecial {
  @media(max-width: 576px){
    margin-bottom: 0 !important;
    transition: .4s all;
  }
}

.bgspecial {
  @media(max-width: 576px){
    background-position: center bottom !important;
    background-size: 100% 115% !important;
  }
}

.upload-option {
  label {
    @media(max-width: 768px){
      width: 100%;
      max-width: 326px;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: .9;
}
</style>
