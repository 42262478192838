<template>
  <footer>
    <div class="content-wrapper">
      <div class="wrapper">
        <p class="text">
          *В акции участвуют контактные линзы TOTAL30, TOTAL30 for Astigmatism,
          DAILIES TOTAL1, DAILIES TOTAL1 Multifocal, PRECISION1, PRECISION1 for Astigmatism  <br>
          1. Фогт Дж., Паттон К. Длительный дневной опыт ношения ежедневных одноразовых
          контактных линз с водной поверхностью. Клиническая оптометрия. 2022/14/. 93-99 <br>
          2. 9 из 10 пользователей согласились с тем, что линзы настолько комфортны,
          что не ощущаются на глазу. Перез-Гомез И., Джилз Т. Европейское исследование
          удовлетворенности пользователей и специалистов новыми водоградиентными
          однодневными контактными линзами. ClinicalOptometry, 12 марта 2014 года. <br>
          3. 82% респондентов оценили уровень комфорта через 16 часов ношения на 9 баллов и
          выше по 10-балльной шкале. Данные исследований Алкон, 2014. Маисса С.; Нельсон Дж.;
          ДеЦензо-Вербетен Т.; Крамер Д.; Мартин А. Оценка смазывающей способности
          контактных линз ежедневной замены из Делефилкона А (Dailies Total 1) после
          ношения. ААО, постер 26 <br>
          4. 8 из 10 пользователей согласились с утверждением "Эти линзы настолько
          комфортны, что я их не чувствую" на 30 день ношения линз. Данные исследования
          Алкон, 2020 <br>
          5.Такер Б. Характеристика свойств поверхности новых однодневных контактных линз из
          силикон-гидрогеля. Постер представлен на конференции Американской Академии Оптометрии 2019. <br>
          6.Анджелини Т., Никсон Р., Дюнн А и др. Вискоэластичность и структура поверхности
          гидрогелей, характеризуемая путем микрореологии. ARVO 2013. Инвест
          Офтальмол Вижн Сайенс 2013; 54: Е-Абстракт 500 <br>
          7. Водоградиентный материал с влагосодержанием внутри линзы 55%, на поверхности
          больше 99.5%. Данные исследования Алкон, 2020. Специальное приложение к
          журналу Вестник Оптометрии, Контактные Линзы 2022 <br> <br>
          Общий период проведения акции, включая период вручения призов: с 3 июня 2024 г.
          по 30 сентября 2024 г. включительно. Информация об организаторе, правилах
          проведения акции, количестве призов, сроков, месте и порядке их получения
          указаны в Правилах акции и размещены на сайте
          <a href="alcon-promo.ru" target="no_blank">alcon-promo.ru</a>.
          Горячая линия Акции: 8-800-505-67-98. *Главным призом являются сертификат на
          сумму 300 000 рублей. Внешний вид призов может отличаться.  ООО «Алкон Фармацевтика»,
          125315, г. Москва, просп. Ленинградский, д. 72, корп. 3. Тел: +7 495 775-68-69,
          +7 495 961-13-33. Факс: +7 495 961-13-39. RU-T30-2400013      
        </p>

        <div class="links">
          <a :href="rules[0].url"
             v-if="rules"
             target="no_blank"
          >
            Правила акции
          </a>

          <div v-b-modal.feedback-modal onclick="ym(94436170,'reachGoal','click_feedback')">Обратная связь</div>
        </div>
      </div>

      <p class="subtext">
        имеются противопоказания, необходимо проконсультироваться со специалистом
      </p>
    </div>
  </footer>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: 'FooterRegion',

  data: () => ({
  }),

  computed: {
    ...mapGetters({
      rules: 'rules',
      policies: 'policies'
    }),
  },
}
</script>

<style scoped lang="scss">
footer {
  background-color: $darker-blue;
  width: 100%;
  height: 100%;
  padding: 22px 60px;
  display: flex;
  justify-content: center;
  background: url('@/assets/footer-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 50px;

  @media(max-width: 768px){
    padding: 16px;
    padding-top: 25px;
  }

  @media(max-width: 576px){
    padding: 0 20px;
    padding-top: 25px;
  }
}

.text {
  color: $white;
  font-size: 10px;
  font-weight: 400;
  font-family: $regular;
  margin-bottom: 2px;
  text-align: justify;

  a {
    color: $white;
    font-weight: 500;
  }
}

.links {
  color: $white;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 40px;
  font-family: 'Roboto', sans-serif;

  @media(max-width: 756px){
    display: flex;
    justify-content: space-between;
    margin-left: 0;
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 15px;
  }

  div {
    cursor: pointer;
    transition: .5s all;

    @media(max-width: 768px){
      margin-left: 0;
    }

    &:hover {
      opacity: .8;
    }
  }

  a {
    color: $white;
    display: block;
    transition: .5s all;

    &:hover {
      opacity: .8;
      text-decoration: none;
    }
  }
}

.content-wrapper {
}

.wrapper {
  display: flex;
  padding: 0;
  position: relative;
  z-index: 10;

  @media(max-width: 756px){
    flex-direction: column-reverse;
  }
}

.subtext {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  color: $white;
  font-family: 'Open Sans Condensed', sans-serif;
  opacity: .3;
  width: 100%;
  margin-top: 15px;
  letter-spacing: 1.5px;
  margin-bottom: 0;
  text-align: center;

  @media(max-width: 1600px){
    font-size: 32px;
  }

  @media(max-width: 1500px){
    letter-spacing: 2.3px;
  }

  @media(max-width: 1200px){
    font-size: 22px;
    letter-spacing: 2.6px;
  }

  @media(max-width: 999px){
    font-size: 18px;
    letter-spacing: 2.1px;
    margin-top: 10px;
    line-height: 90%;
  }

  @media(max-width: 576px){
    font-size: 17px;
    letter-spacing: 2.45px;
    line-height: 110%;
  }

  @media(max-width: 375px){
    letter-spacing: 2px;
    font-size: 16px;
  }

  @media(max-width: 360px){
    letter-spacing: 2.1px;
    font-size: 15px;
  }

  @media(max-width: 340px){
    letter-spacing: 1.4px;
    font-size: 14px;
  }
}
</style>
