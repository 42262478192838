<template>
  <div class="w-100"
       id="registration">
    <div class="phone-login">
      <form ref="form"
            class="modal-form"
            style="margin-left: -8px; margin-top: 20px;"
            @submit.stop.prevent="submit">
        <div class="">
          <div class="position-relative"
               v-if="!unregisteredPhone">
            <b-form-group
              label-for="phone-input"
               invalid-feedback=""
               v-mask="'+7(###)-###-##-##'"
            >
              <div class="position-relative">
                  <b-form-input
                    @click="putPlaceholder"
                    id="phone-input"
                    placeholder="+7 (___) ___-__-__"
                    v-model="$v.item.phone.$model"
                    maxlength="17"
                    :state="validateState($v.item.phone)"
                    required
                  ></b-form-input>

                  <div class="input-icon bottom" v-if="$v.item.phone.$error || invalidPhone">
                    <img src="@/assets/svg/incorrect.svg" />
                  </div>

                  <div class="input-icon bottom" v-if="!$v.item.phone.$error && item.phone.length > 5 && !invalidPhone">
                    <img src="@/assets/svg/correct.svg" />
                  </div>
                </div>
              </b-form-group>

            <div class="error"
                 v-if="invalidPhone">
              {{ phoneError }}
            </div>
          </div>

          <div class="position-relative unregistered"
               v-if="unregisteredPhone">
            <b-form-group
              label-for="phone-input"
               invalid-feedback=""
               v-mask="'+7(###)-###-##-##'"
            >
              <div class="position-relative">
                <b-form-input
                  @click="putPlaceholder"
                  id="phone-input"
                  placeholder="+7 (___) ___-__-__"
                  v-model="$v.item.phone.$model"
                  maxlength="17"
                  readonly
                  :state="validateState($v.item.phone)"
                  required
                ></b-form-input>

                <div class="input-icon bottom"
                     v-if="$v.item.phone.$error || invalidPhone"
                     style="bottom: 0 !important;">
                  <img src="@/assets/svg/incorrect.svg" />
                </div>

                <div class="input-icon bottom"
                     v-if="!$v.item.phone.$error && item.phone.length > 5 && !invalidPhone"
                     style="bottom: 0 !important;">
                  <img src="@/assets/svg/correct.svg" />
                </div>
              </div>
            </b-form-group>

            <div class="error"
                 v-if="invalidPhone">
              {{ phoneError }}
            </div>
          </div>

          <div class="position-relative">
            <b-form-group
              label-for="email-input"
              invalid-feedback=""
            >
              <div class="position-relative">
                <b-form-input
                  id="email-input"
                  placeholder="Введите e-mail"
                  v-model="$v.item.email.$model"
                  :state="validateState($v.item.email)"
                  required
                ></b-form-input>

                <div class="input-icon" v-if="$v.item.email.$error  || invalidEmail">
                  <img src="@/assets/svg/incorrect.svg" />
                </div>

                <div class="input-icon" v-if="!$v.item.email.$error && item.email.length > 1 && !invalidEmail">
                  <img src="@/assets/svg/correct.svg" />
                </div>
              </div>
           </b-form-group>

           <div class="error"
                style="margin-top: -8px;"
                v-if="invalidEmail">
             {{ emailError }}
           </div>
         </div>
        </div>

        <div class="">
          <div class="position-relative height"
               :class="{ 'addHeight' : (invalidName || invalidSurname) }">
            <b-form-group
              class="position-relative"
              label-for="name-input"
              :invalid-feedback="nameError"
            >
              <div class="position-relative">
                <b-form-input
                  id="name-input"
                  placeholder="Ваше имя"
                  v-model="$v.item.name.$model"
                  :state="validateState($v.item.name)"
                  required
                ></b-form-input>

                <div class="input-icon" v-if="$v.item.name.$error || invalidName">
                  <img src="@/assets/svg/incorrect.svg" />
                </div>

                <div class="input-icon" v-if="!$v.item.name.$error && item.name.length > 1 && !invalidName">
                  <img src="@/assets/svg/correct.svg" />
                </div>
              </div>
            </b-form-group>

            <div class="error"
                 v-if="invalidName">
              {{ nameError }}
            </div>
          </div>

          <div class="position-relative height"
               :class="{ 'addHeight' : (invalidName || invalidSurname) }">
            <b-form-group
              class="position-relative"
              label-for="surname-input"
              :invalid-feedback="surnameError"
            >
              <div class="position-relative">
                <b-form-input
                  id="surname-input"
                  placeholder="Ваша фамилия"
                  v-model="$v.item.surname.$model"
                  :state="validateState($v.item.surname)"
                  required
                ></b-form-input>

                <div class="input-icon" v-if="$v.item.surname.$error || invalidSurname">
                  <img src="@/assets/svg/incorrect.svg" />
                </div>

                <div class="input-icon" v-if="!$v.item.surname.$error && item.surname.length > 1 && !invalidSurname">
                  <img src="@/assets/svg/correct.svg" />
                </div>
              </div>

              <div class="error"
                   style="margin-top: -10px;"
                   v-if="invalidSurname">
                {{ surnameError }}
              </div>
           </b-form-group>
         </div>
        </div>

        <div class="">
          <div class="position-relative">
            <b-form-group
              label-for="password-input"
              invalid-feedback=""
            >
              <div class="position-relative">
                <b-form-input
                  id="password-input"
                  placeholder="Введите пароль"
                  v-model="$v.item.password.$model"
                  :state="validateState($v.item.password)"
                  required
                  type="password"
                ></b-form-input>

                <div class="input-icon" v-if="invalidPassword">
                  <img src="@/assets/svg/incorrect.svg" />
                </div>
              </div>
           </b-form-group>

           <div class="error"
                style="margin-top: -8px;"
                v-if="invalidPassword">
             {{ passwordError }}
           </div>
         </div>

         <div class="position-relative">
            <b-form-group
             label-for="password-input"
             invalid-feedback=""
             >
             <div class="position-relative">
               <b-form-input
                 id="password-input"
                 placeholder="Подтвердите пароль"
                 v-model="$v.item.passwordConfirmation.$model"
                 :state="validateState($v.item.passwordConfirmation)"
                 required
                 type="password"
               ></b-form-input>

               <div class="input-icon" v-if="invalidPassword">
                 <img src="@/assets/svg/incorrect.svg" />
               </div>
             </div>
           </b-form-group>

           <div class="error"
                style="margin-top: -15px !important;"
                v-if="invalidPassword">
             {{ passwordError }}
           </div>
         </div>
        </div>
      </form>

      <div class="check-wrap" style="display: flex;flex-direction: column;align-items: center;">
        <b-form-checkbox
          id="checkbox-policies"
          class="mt-2 checkbox desktop"
          v-model="$v.item.policies.$model"
          :state="validateState($v.item.policies)"
          name="policies"
          required
        >
        Я даю согласие на обработку своих персональных данных <br> в соответствии с
          <a :href="privacyPolicy[0].url"
             target="_blank">Политикой в отношении
             <span style="text-decoration:underline;">обработки <br> персональных данных</span>
             ООО “Алкон Фармацевтика”</a>
        </b-form-checkbox>

        <b-form-checkbox
          id="checkbox-policies"
          class="mt-2 checkbox mobile"
          v-model="$v.item.policies.$model"
          :state="validateState($v.item.policies)"
          name="policies"
          required
        >
        Я даю согласие на обработку своих персональных данных в соответствии с
          <a :href="privacyPolicy[0].url"
             target="_blank">Политикой в отношении
             <span style="text-decoration:underline;">обработки персональных данных</span>
             ООО “Алкон Фармацевтика”</a>
        </b-form-checkbox>

        <b-button class="button button-orange"
                  type="submit"
                  onclick="ym(94436170,'reachGoal','send_form')"
                  :disabled="disabledBtn"
                  @click="submit">Зарегистрироваться</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,999}$/)

export default {
  name: 'Registration',

  mixins: [validationMixin],

  validations: {
    item: {
      phone: {
        required,
        minLength: minLength(17),
      },
      email: {
        required,
        minLength: minLength(2),
        email
      },
      password: {
        required,
      },
      passwordConfirmation: {
        required,
      },
      name: {
        text,
        minLength: minLength(2),
        required
      },
      surname: {
        text,
        minLength: minLength(2),
        required
      },
      policies: {
        required
      }
    }
  },

  components: {
  },

  data() {
    return {
      item: {
        phone: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        name: '',
        surname: '',
        policies: ''
      },
      invalidPassword: false,
      invalidEmail: false,
      invalidPhone: false,
      invalidName: false,
      invalidSurname: false,
      phoneError: 'Некорректное поле',
      emailError: 'Некорректное поле',
      passwordError: 'Некорректное поле',
      nameError: '',
      surnameError: ''
    }
  },

  watch: {
    unregisteredPhone(){
      if(this.unregisteredPhone.length > 1){
        console.log(this.unregisteredPhone)

        this.item.phone = this.unregisteredPhone
      }
    }
  },

  mounted(){
    this.item.phone = this.unregisteredPhone
  },


  computed: {
    ...mapGetters({
      unregisteredPhone: 'unregisteredPhone',
      personalData: 'personalData',
      privacyPolicy: 'policies'
    }),

    disabledBtn(){
      if ((this.$v.item.$anyError) || (this.item.phone.length < 1) || (this.item.email.length < 1) || (this.item.name.length < 1) ||
          (this.item.surname.length < 1) || (this.item.password.length < 1) || (this.item.passwordConfirmation.length < 1))
        return true
    }
  },

  methods: {
    ...mapMutations({
      saveToken: 'saveToken',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText',
      saveRegisteredPhone: 'saveRegisteredPhone'
    }),

    putPlaceholder(){
      if (this.item.phone.length < 3){
        this.item.phone = '+79'
      }
    },

    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },

    submit() {
      this.$v.item.$touch()

      this.invalidEmail = false
      this.invalidPhone = false
      this.invalidPassword = false

      if (this.$v.item.$anyError)
        return false

      axios.post('auth/register', {
        phone: this.item.phone,
        surname: this.item.surname,
        name: this.item.name,
        email: this.item.email,
        password: this.item.password,
        password_confirmation: this.item.passwordConfirmation,
        accept_policies: this.item.policies.toString()
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
        }
      }).then((response) => {
        this.saveToken(response.data.access.token)
        localStorage.setItem('token', response.data.access.token)
        this.$store.dispatch('getUser')
        // this.$store.dispatch('getCerts')

        this.getSuccessText("Регистрация прошла успешно")
        this.$bvModal.show('success-modal')
      }).catch((error) => {
        if ((error.response.status == "409")){
          this.invalidEmail = true
          this.emailError = error.response.data.message
          this.getErrorText('Вы уже зарегистрированы, пожалуйста, авторизуйтесь.')
          this.$bvModal.show('error-modal')

          return
        }

        if (error.response.data.errors){
          if ((error.response.data.message === "Пользователя с таким номером телефона уже существует.")){
            this.invalidPhone = true
            this.phoneError = error.response.data.errors.phone[0]

            this.saveRegisteredPhone(this.item.phone)

            this.getErrorText('Вы уже зарегистрированы, пожалуйста, авторизуйтесь.')
            this.$bvModal.show('error-modal')

            return
          }

          if ((error.response.data.errors.phone) && (error.response.data.errors.email)){
            this.invalidEmail = true
            this.invalidPhone = true
            this.phoneError = error.response.data.errors.phone[0]
            this.emailError = error.response.data.errors.email[0]

            this.saveRegisteredPhone(this.item.phone)

            this.getErrorText('Вы уже зарегистрированы, пожалуйста, авторизуйтесь.')
            this.$bvModal.show('error-modal')

            return
          }

          if ((error.response.data.errors.phone)){
            this.invalidPhone = true
            this.phoneError = error.response.data.errors.phone[0]
          }

          if ((error.response.data.errors.name)){
            this.invalidName = true
            this.nameError = error.response.data.errors.name[0]
          }

          if ((error.response.data.errors.surname)){
            this.invalidSurname = true
            this.surnameError = error.response.data.errors.surname[0]
          }

          if ((error.response.data.errors.email)){
            this.invalidEmail = true
            this.emailError = error.response.data.errors.email[0]
          }

          if (error.response.data.errors.password){
            this.invalidPassword = true
            this.passwordError = error.response.data.errors.password[0]
          }
        } 
      })
    },
  }
}
</script>

<style scoped lang="scss">
.form-group {
  position: relative;
  font-family: $regular !important;
}

.form-control {
  color: $darker-grey !important;
  background-color: $white !important;
  border: 1px solid transparent;
  transition: .6s all;
  height: 58px;
  display: flex;
  padding-left: 25px !important;
  align-items: center;
  font-size: 18px;
  margin-top: 5px;
  border-radius: 60px;
  max-width: 340px;
  min-width: 340px;
  margin: 0 8px;
  margin-bottom: 9px;
  font-family: $regular;
  border: 2px solid white;
  box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1) !important;

  &::placeholder {
    color: #6882B9 !important;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid $darker-blue;
  }

  @media(max-width: 999px){
    min-width: 280px;
    max-width: 300px;
    height: 48px !important;
  }

  @media(max-width: 576px){
    height: 42px !important;
    font-size: 14px;
  }

  @media(max-width: 400px){
    max-width: 250px;
    min-width: 230px;
  }

  @media(max-width: 350px){
    min-width: 200px;
  }
}

.checkbox {
  margin-left: 15px;
  color: $white !important;

  @media(max-width: 999px){
    margin-left: 0;
  }
}

.button {
  width: 100%;
  max-width: 347px;
  border-radius: 95px;
  margin-top: 20px;
  height: 48px;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  @media(max-width: 999px){
    margin-left: 0;
    margin-top: 15px;
    max-width: 300px;
    height: 48px;
  }

  @media(max-width: 576px){
    height: 42px !important;
  }

  @media(max-width: 400px){
    max-width: 250px;
    min-width: 230px;
  }

  @media(max-width: 350px){
    min-width: 200px;
  }
}

.login-option {
  font-family: $regular;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  max-width: 340px;
  text-decoration: underline;
}

.error {
  margin-top: -10px;
  width: 100%;
  max-width: 310px;
  text-align: left;
  font-size: 6px;
  color: white;
  line-height: 95%;
  position: absolute;
  font-family: $regular;
  margin-left: 35px;

  @media(max-width: 450px){
    max-width: 200px !important;
  }
}

.d-flex {
  @media(max-width: 999px){
    flex-direction: column !important;
  }

  &.align-items-center {
    @media(max-width: 999px){
      align-items: flex-start !important;
    }
  }
}

.addHeight {
  height: 95px;

  @media(max-width: 768px){
    height: 95px;
  }
}

.height {

}

.form-group {
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
}

.check-wrap {
  @media(max-width: 1100px){
    max-width: 340px;
  }

  @media(max-width: 876px){
    max-width: 290px;
  }

  div {
    @media(max-width: 876px){
      max-width: 290px;
      white-space: normal;
    }
  }
}
</style>
