 <template>
  <section class="section-wrapper">

    <div class="plug-wrapper">
      <div class="plug">
        <div class="plug-step">
          <div class="step">1</div>

          <div class="d-flex flex-column justify-content-between">
            <p class="title">
              покупайте* линзы <br> TOTAL30, TOTAL1 <br>
              и PRECISION1
            </p>

            <p class="subtext">
              c 3 июня по 31 августа 2024 года
            </p>
          </div>
        </div>

        <div class="plug-step">
          <div class="step">2</div>

          <div class="d-flex flex-column justify-content-between">
            <p class="title underline">
              регистрируйте <br> чеки о покупке
            </p>
          </div>
        </div>

        <div class="plug-step">
          <div class="step">3</div>

          <div class="d-flex flex-column justify-content-between">
            <p class="title">
              выигрывайте <br>
              <span style="text-decoration: underline;">суперпризы!</span>
            </p>
          </div>
        </div>
      </div>

      <img loading="lazy" class="prizes" src="@/assets/prizes.webp" />
    </div>
  </section>
</template>

<script>

export default {
  name: 'Prizes',

  data: () => ({
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: $white;
  padding-top: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;

  @media(max-width: 756px){
    padding-top: 30px;
  }

  @media(max-width: 1050px){
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 60px;
  }

  @media(max-width: 756px){
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media(max-width: 576px){
    padding-bottom: 30px;
  }
}

.prizes {
  max-width: 420px;
  margin-left: 20px;

  @media(max-width: 1050px){
    max-width: 360px;
  }

  @media(max-width: 756px){
    max-width: 280px;
  }
}

.mobile-br {
  @media(max-width: 756px){
    display: block !important;
  }
}

.button {
  max-width: 250px;
  font-size: 18px;
  height: 54px;
  line-height: 18px;
}

.img-text {
  margin-top: 30px;
  font-family: $regular;

  @media(max-width: 768px){
    font-family: $regular;
  }

  p {
    margin-bottom: 0;
    text-transform: uppercase;
    color: $dark-blue;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;

    @media(max-width: 756px){
      font-size: 22px;
    }

    @media(max-width: 768px){
      font-weight: 700;
      font-size: 19px;
      line-height: 116%;
    }

    &:first-child {
      margin-right: 13px;
      font-size: 72px;
      line-height: 50px;

      @media(max-width: 768px){
        font-weight: 32px;
        font-size: 46px;
      }
    }
  }

  span {
    background-color: #8dccf3;
    padding: 0 3px;
  }
}

.plug-wrapper {
  width: 100%;
  margin-top: -230px;
  justify-content: center;
  display: flex;
  max-width: 1700px;
  padding: 70px 20px;
  background: $white;
  box-shadow: 0px 0px 26.8px rgba(0, 48, 135, 0.1);
  border-radius: 40px;

  @media(max-width: 1400px){
    max-width: 1300px;
  }

  @media(max-width: 1200px){
    max-width: 880px;
  }

  @media(max-width: 1050px){
    max-width: none;
    padding-top: 48px;
    margin-top: -130px;
    padding-bottom: 50px;
    justify-content: space-between;
  }

  @media(max-width: 756px){
    padding: 26px 15px;
    margin-top: -120px;
    align-items: center;
    flex-direction: column;
  }

  @media(max-width: 576px){
    padding: 25px 10px;
    margin-top: -80px;
  }
}

.plug {
  display: flex;
  color: $white;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;

  @media(max-width: 760px){
    flex-direction: column;
    padding: 26px 15px;
    align-items: center;
  }

  @media(max-width: 576px){
    padding: 25px 10px;
  }

  @media(max-width: 420px){
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .plug-step {
    display: flex;
    margin-bottom: 40px;

    @media(max-width: 756px){
      margin-left: 0;
      margin-right: 0;
      margin-top: 15px;
      margin-bottom: 15px;
      width: 285px;
    }

    @media(max-width: 360px){
      width: 250px;
    }
  }

  .step {
    background-color: $blue;
    border-radius: 50%;
    color: $white;
    font-weight: 700;
    font-size: 20px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 15px;
    line-height: 80%;

    @media(max-width: 756px){
      width: 24px;
      height: 24px;
      margin-right: 7px;
      font-size: 14px;
    }
  }

  p {
    margin-bottom: 0;
  }

  .title {
    font-size: 28px;
    line-height: 100%;
    font-weight: 700;
    font-family: $regular;
    text-transform: uppercase;
    margin-bottom: 7px;
    color: $darker-blue;
    line-height: 110%;

    @media(max-width: 1600px){
      font-size: 22px;
    }

    @media(max-width: 756px){
      font-size: 18px;
    }

    @media(max-width: 756px){
      font-size: 16px;
      font-weight: 700;
      line-height: 110%;
      font-family: $regular;
    }
  }

  .subtext {
    line-height: 106%;
    color: $darker-grey;

    @media(max-width: 756px){
      font-size: 14px;
      line-height: 100%;
      font-family: $regular;
    }
  }
}
</style>
