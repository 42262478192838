<template>
  <section class="section-wrapper" id="prizes">
    <div class="content-wrapper">
      <h3 class="section-title">
        БОЛЕЕ 30 ПРИЗОВ КАЖДЫЙ МЕСЯЦ!
      </h3>

      <p class="subtext">
        Загружайте больше чеков о покупке и увеличивайте шансы на победу
      </p>

      <div class="prizes-wrapper">
        <div class="lense" v-if="cert">
          <div class="gift-card gift-card__cert">
            <div class="gift-subtitle gift-subtitle__desktop">
              Участвуйте в розыгрыше Главного Приза <br>
              при покупке от двух упаковок в месяц
            </div>

            <div class="gift-subtitle gift-subtitle__mobile">
              Участвуйте в розыгрыше Главного Приза
              при покупке от двух упаковок в месяц
            </div>

            <div class="gift-title gift-title__desktop">
              Сертификат <br>
              на путешествие <br>
              на сумму 300 000 рублей
            </div>

            <div class="gift-title gift-title__mobile">
              Сертификат <br>
              на путешествие <br>
              на сумму <br> 300 000 рублей
            </div>

            <div class="gift-text">
              Осуществите мечту побывать там, где вы еще не были. Или посетите места,
              которые любите больше всего. Отдыхайте и наслаждайтесь каждым моментом
              в линзах, которые не чувствуешь!
            </div>
          </div>

          <button class="button button-orange button-action button-action__left disabled">
            <
          </button>

          <button class="button button-orange button-action button-action__right"
                  @click="openPrize2()">
            >
          </button>

          <router-link class="button button-orange participate"
                       :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'">
            Участвовать
          </router-link>
        </div>

        <div class="lense" v-if="prize2">
          <div class="gift-card gift-card__prize2">
            <div class="gift-subtitle gift-subtitle__desktop">
              Участвуйте в розыгрыше суперпризов <br>
              при покупке от одной упаковки в месяц
            </div>

            <div class="gift-subtitle gift-subtitle__mobile">
              Участвуйте в розыгрыше суперпризов
              при покупке от одной упаковки в месяц
            </div>

            <div class="gift-title gift-title__desktop">
              Электрический <br> самокат KUGOO KIRIN <br> Mini
            </div>

            <div class="gift-title gift-title__mobile">
              Электрический <br> самокат KUGOO <br> KIRIN Mini
            </div>

            <div class="gift-text">
              Мчаться навстречу приключениям
              и наслаждаться летними пейзажами в линзах Alcon
            </div>
          </div>

          <button class="button button-orange button-action button-action__left"
                  @click="openCert()">
            <
          </button>

          <button class="button button-orange button-action button-action__right"
                  @click="openPrize3()">
            >
          </button>

          <router-link class="button button-orange participate"
                       :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'">
            Участвовать
          </router-link>
        </div>

        <div class="lense" v-if="prize3">
          <div class="gift-card gift-card__prize3">
            <div class="gift-subtitle gift-subtitle__desktop">
              Участвуйте в розыгрыше суперпризов <br>
              при покупке от одной упаковки в месяц
            </div>

            <div class="gift-subtitle gift-subtitle__mobile">
              Участвуйте в розыгрыше суперпризов
              при покупке от одной упаковки в месяц
            </div>

            <div class="gift-title gift-title__desktop">
              Фотоаппарат <br> моментальной <br> печати Kodak С300R <br> White
            </div>

            <div class="gift-title gift-title__mobile">
              Фотоаппарат <br> моментальной <br> печати Kodak <br> С300R White
            </div>

            <div class="gift-text">
              Запечатлеть самые яркие моменты
              и счастливые эмоции в линзах Alcon
            </div>
          </div>

          <button class="button button-orange button-action button-action__left"
                  @click="openPrize2()">
            <
          </button>

          <button class="button button-orange button-action button-action__right"
                  @click="openPrize4()">
            >
          </button>

          <router-link class="button button-orange participate"
                       :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'">
            Участвовать
          </router-link>
        </div>

        <div class="lense" v-if="prize4">
          <div class="gift-card gift-card__prize4">
            <div class="gift-subtitle gift-subtitle__desktop">
              Участвуйте в розыгрыше суперпризов <br>
              при покупке от одной упаковки в месяц
            </div>

            <div class="gift-subtitle gift-subtitle__mobile">
              Участвуйте в розыгрыше суперпризов
              при покупке от одной упаковки в месяц
            </div>

            <div class="gift-title gift-title__desktop">
              Умная колонка <br>Яндекс Станция 2 <br>с Алисой
            </div>

            <div class="gift-title gift-title__mobile">
              Умная <br>колонка Яндекс <br>Станция 2 <br> с Алисой
            </div>

            <div class="gift-text">
              Включать любимую музыку
              и танцевать без остановки
              в линзах Alcon
            </div>
          </div>

          <button class="button button-orange button-action button-action__left"
                  @click="openPrize3()">
            <
          </button>

          <button class="button button-orange button-action button-action__right disabled">
            >
          </button>

          <router-link class="button button-orange participate"
                       :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'">
            Участвовать
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'BuyLenses',

  data: () => ({
    cert: true,
    prize2: false,
    prize3: false,
    prize4: false
  }),

  computed: {
  },

  methods: {
    openCert(){
      this.prize2 = false
      this.cert = true
      this.prize3 = false
      this.prize4 = false
    },

    openPrize2(){
      this.prize2 = true
      this.cert = false
      this.prize3 = false
      this.prize4 = false
    },

    openPrize3(){
      this.prize2 = false
      this.cert = false
      this.prize3 = true
      this.prize4 = false
    },

    openPrize4(){
      this.prize2 = false
      this.cert = false
      this.prize3 = false
      this.prize4 = true
    },
  }
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: white;
  padding-top: 60px;
  padding-bottom: 40px;
  color: $darker-blue;
  display: flex;
  justify-content: center;

  @media(max-width: 999px){
    padding-left: 24px;
    padding-right: 24px;
  }

  @media(max-width: 768px){
    padding-bottom: 70px;
    padding-top: 15px;
    background-size: 420%;
    background-position: bottom -15px right;
  }

  @media(max-width: 576px){
    padding: 0 20px;
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.desktop {
  @media(max-width: 768px){
    display: none;
  }
}

.mobile {
  display: none;

  @media(max-width: 768px){
    display: block;
  }
}

.participate {
  position: absolute;
  bottom: -20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  @media(max-width: 576px){
    bottom: 0;
    top: 0;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 183px;
  }
  
  @media(max-width: 450px){
    bottom: 22%;
  }

  @media(max-width: 440px){
    bottom: 18%;
  }

  @media(max-width: 430px){
    bottom: 15%;
  }

  @media(max-width: 420px){
    bottom: 10%;
  }

  @media(max-width: 400px){
    bottom: 5%;
  }

  @media(max-width: 380px){
    bottom: 0%;
  }

  @media(max-width: 370px){
    bottom: -4%;
  }

  @media(max-width: 350px){
    bottom: -5%;
  }

  @media(max-width: 330px){
    bottom: -15%;
  }
}

.prizes-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1500px;
  position: relative;

  @media(max-width: 1600px){
    max-width: 1150px;
  }

  @media(max-width: 1400px){
    max-width: 1000px;
  }

  @media(max-width: 1200px){
    padding: 0px;
    max-width: 900px;
  }

  @media(max-width: 999px){
    max-width: none;
  }

  @media(max-width: 768px){
  }

  @media(max-width: 576px){
    padding: 0;
  }
}

.lense {
  max-width: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(max-width: 768px){
    max-width: 100%;
    position: relative;
  }
}

.gift-card {
  background-color: $white;
  box-shadow: 0px 0px 26.8px rgba(0, 48, 135, 0.1);
  border-radius: 40px;
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 52px;
  padding-bottom: 127px;
  padding-left: 70px;
  height: 515px;

  @media(max-width: 1600px){
    background-position: center right;
  }

  @media(max-width: 1399px){
    padding-left: 50px;
  }

  @media(max-width: 1300px){
    background-image: url('@/assets/cert@tablet.webp');
    padding-left: 25px;
  }

  @media(max-width: 576px){
    background-image: url('@/assets/cert@mobile.webp');
    background-position: bottom;
    background-size: contain;
    padding-top: 32px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 300px;
    height: 650px;
  }

  &__cert {
    background-image: url('@/assets/cert-bg.webp');

    @media(max-width: 1399px){
      background-position: center left;
    }

    @media(max-width: 1300px){
      background-image: url('@/assets/cert@tablet.webp');
    }

    @media(max-width: 576px){
      background-image: url('@/assets/cert@mobile.webp');
      background-position: bottom;
    }
  }

  &__prize2 {
    background-image: url('@/assets/prize-2.webp');
    background-size: contain;

    @media(max-width: 1399px){
      background-position: center right -130px;
    }

    @media(max-width: 1300px){
      background-image: url('@/assets/prize-2@tablet.webp');
      background-position: center right;
    }

    @media(max-width: 576px){
      background-image: url('@/assets/prize-2@mobile.webp');
      background-position: bottom;
    }
  }

  &__prize3 {
    background-image: url('@/assets/prize-3.webp');
    background-size: contain;

    @media(max-width: 1399px){
      background-position: center right -130px;
    }

    @media(max-width: 1300px){
      background-image: url('@/assets/prize-3@tablet.webp');
      background-position: center right;
    }

    @media(max-width: 576px){
      background-image: url('@/assets/prize-3@mobile.webp');
      background-position: bottom;
    }
  }

  &__prize4 {
    background-image: url('@/assets/prize-4.webp');
    background-size: contain;

    @media(max-width: 1399px){
      background-position: center right -130px;
    }

    @media(max-width: 1300px){
      background-image: url('@/assets/prize-4@tablet.webp');
      background-position: center right;
    }

    @media(max-width: 576px){
      background-image: url('@/assets/prize-4@mobile.webp');
      background-position: bottom;
    }
  }

  .gift-subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    text-transform: uppercase;
    color: #243E89;
    margin-bottom: 56px;

    @media(max-width: 1300px){
      font-size: 16px;
    }

    @media(max-width: 768px){
      font-size: 14px;
    }

    @media(max-width: 576px){
      margin-bottom: 24px;
      font-size: 12px;
    }

    &__desktop {
      @media(max-width: 576px){
        display: none;
      }
    }

    &__mobile {
      display: none;

      @media(max-width: 576px){
        display: block;
      }

      @media(max-width: 450px){
        margin-bottom: 14px;
      }
    }
  }

  .gift-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-transform: uppercase;
    color: #243E89;
    margin-bottom: 24px;

    @media(max-width: 1300px){
      font-size: 30px;
    }

    @media(max-width: 768px){
      font-size: 26px;
    }

    @media(max-width: 576px){
      padding-bottom: 0px;
      font-size: 22px;
    }

    &__desktop {
      @media(max-width: 576px){
        display: none;
      }
    }

    &__mobile {
      display: none;

      @media(max-width: 576px){
        display: block;
      }

      @media(max-width: 450px){
        margin-bottom: 10px;
      }
    }
  }

  .gift-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $darker-grey;
    max-width: 450px;

    @media(max-width: 1300px){
      font-size: 14px;
    }

    @media(max-width: 1050px){
      font-size: 14px;
      max-width: 380px;
    }

    @media(max-width: 768px){
      font-size: 12px;
      line-height: 110%;
    }

    @media(max-width: 576px){
      padding-bottom: 40px;
    }
  }
}

.section-title {
  text-align: center;
  color: $darker-blue;
  font-weight: 700;
  font-family: $regular;
  margin-bottom: 8px;

  @media(max-width: 576px){
    font-size: 24px;
    text-align: left;

    br {
      display: none;
    }

    .mobile {
      display: none;

      @media(max-width: 768px){
        display: block;
      }
    }
  }
}

.subtext {
  color: $darker-grey;
  margin-bottom: 35px;
  font-size: 20px;
  font-weight: 400;
  font-family: $regular;
  text-align: center;
  line-height: 140%;

  @media(max-width: 768px){
    font-size: 14px;
    margin-bottom: 30px;
    text-align: left;
  }

  .mobile {
    display: none !important;

    @media(max-width: 768px){
      display: block !important;
    }
  }
}

.button {
  color: $darker-blue;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  white-space: nowrap;

  @media(max-width: 768px){
  }

  &:hover {
    text-decoration: none;
  }
}

.content-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  max-width: none;
}

.button-action {
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
  width: 44px !important;
  height: 44px !important;
  position: absolute;

  @media(max-width: 1300px){
    width: 36px !important;
    height: 36px !important;
  }

  @media(max-width: 576px){
    width: 30px !important;
    height: 30px !important;
    bottom: 23%;
  }

  &__right {
    right: -25px;

    @media(max-width: 1300px){
      right: -15px;
    }

    @media(max-width: 576px){
      right: 10px;
    }
  }

  &__left {
    left: -25px;

    @media(max-width: 1300px){
      left: -15px;
    }

    @media(max-width: 576px){
      left: 10px;
    }
  }
}

.disabled {
  background: $light-grey;
  pointer-events: none;
}
</style>
